import React from 'react'
import "../CSS/Socials.css";

function Socials(props) {
    const { data } = props;
  return (
    <div>
      <div class="social-link-container">
  <div class="social-link twitter">
    <a href={data?data.tweetUrl:""}><i class="fab fa-twitter"></i></a>
  </div>

  <div class="social-link instagram">
    <a href={data?data.instagram:""}><i class="fab fa-instagram"></i></a>
  </div>

  <div class="social-link linkedin">
    <a href={data?data.linkedIn:""}><i class="fab fa-linkedin"></i></a>
  </div>
</div>


    </div>
  )
}

export default Socials;