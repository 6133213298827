import {AxiosConnect} from "../AxiosConnect";

export async function GetSurveysById(id){
    try {
     const {data} = await AxiosConnect.get(`/v1/survey/get-survey/${id}`);   
     console.log({data});
     return {
        bool:true,
        data
     }
    } catch (error) {
        handleError(error);
        return {
            bool:false,
            data:error.message
         }
    }
}
async function  handleError(error){
    const errorMessage =error.request.responseText?JSON.parse(error.request.responseText).message:"Unknown Error";
    if(errorMessage == "data.reduce is not a function"){
        alert("Submit Again");
        console.log({errorMessage});
    }else{
        alert(errorMessage);
        console.log({errorMessage});
    }
   
    return {
        bool:false,
        data:errorMessage
        }
}
export async function GetAllSurveys(){
   try{
    const {data} = await AxiosConnect.get("/v1/survey/all-surveys");
    if(data){
        console.log(data);
        return {
            bool:true,
            data
         }
    }else{
        console.log({data});
        alert("Something went wrong!")
        return {
            bool:false,
            data
         }
    }
   }catch(error){
        handleError(error);
        return {
            bool:false,
            data:error.message
         }
   }
}
export async function AddSurvey(body){
    try {
        const {data} = await AxiosConnect.post("v1/survey/add-survey",body);
        console.log({data});
        return {
            bool:true,
            data
         }
    } catch (error) {
       handleError(error);
        return {
            bool:false,
            data:error.message
         }
    }
}
export async function AnswerSurvey(survey_id,answers){
    const body = {
        answers
    }
    try {
        const {data} = await AxiosConnect.post(`v1/survey/answer-survey/${survey_id}`,body);
        console.log({data});
        return {
            bool:true,
            data
         }
    } catch (error) {
        handleError(error);
        return {
            bool:false,
            data:error.message
         }
    }
}
