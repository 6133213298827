import React, { useEffect, useState } from 'react'
import {GetSurveysById,AnswerSurvey} from "../Func/GetSurveysById";
import { useParams } from 'react-router-dom';
import Socials from './Socials';
import { Helmet } from 'react-helmet';

const Survey = () => {
  const {survey_id,id} = useParams();
  // Declare state variables
  const [questions, setQuestions] = useState(null); // array of questions
  const [answers, setAnswers] = useState([]); // array of answers
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [currentQuestion, setCurrentQuestion] = useState(0); // index of current question
  const [selectedOption, setSelectedOption] = useState(null); // selected option for current question
  const [submitting, setSubmitting] = useState(false); // flag to indicate if survey is being submitted
  const[popUp,setPopUp] =useState(false);
  const[res_data,setRes_data] = useState(null);
  const[submit,setSubmit] = useState("Submit"); // flag to indicate if..
  const [imageHeader,setImageHeader] = useState("");
  // Fetch questions from API
  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const link = `${survey_id}?id=${id}`;
        //console.log(link)
        const response = await GetSurveysById(link);
        if(response.bool == true){
          //console.log(response.data.survey);
          setImageHeader(response.data.survey.imageHeader);
          setQuestions([response.data.survey]);
        }else{
          setQuestions(null);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchQuestions();
  }, []);

  const changePopUp=(bool)=>{
    setPopUp(bool);
  }
  // Handle option selection
  const handleOptionSelect = (option) => {
    setSelectedOption(option);
  };

  // Handle submission of survey
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const question = survey_id;
      //questions[currentQuestionIndex];
      console.log({answerLen:answers.length});
      // const answer = question.length - answers.length == 1 ?[...answers, selectedOption]: selectedOption; // add null values to make the answer array length match the question length
      setAnswers(prevAnswers => [...prevAnswers, { question, answers:selectedOption }]);

      // Move to next question or submit answers
      const next = questions[currentQuestionIndex].question.length;
      console.log({questionLen:next});
      if (currentQuestion + 1 < next) {
        setCurrentQuestionIndex(currentQuestionIndex);
        setCurrentQuestion(currentQuestion + 1);
        setSelectedOption(null);
        setSubmitting(false);
      } else {
        setSubmit("Submitting...");
        setSubmitting(true);
        //console.log({answers});
        setSubmit("Evaluating answers...");
        const {bool,data} = await AnswerSurvey(survey_id,answers);
        // Show success message
        if(bool == true){
          //console.log(data);
          changePopUp(true);
          setSelectedOption(null);
          setCurrentQuestion(0);
          setCurrentQuestionIndex(0);
          setAnswers([]);
          setSubmit("Done");
          console.log({rate:data.rate});
          setRes_data({msg:"Thanks for answering the survey.",
          rate:data.rate == false?false:data.rate,
          share:data.shareableLink
          });
          setCurrentQuestion(0);
        }else{
         //console.log({data,error:true});
         setSubmit("Submit Again");
      }
      setSubmitting(false);
    
    };
    } catch (error) {
      console.error(error);
      setSubmitting(false);
    }
  };

  return (
    <div className='pages'>
      
      {
        questions && questions !== null ? (
          <div className='question'>
             <Helmet>
        <title>{questions[currentQuestionIndex].topic}</title>
        <meta name="description" content={questions[currentQuestionIndex].description} />
        <meta property="og:title" content={questions[currentQuestionIndex].topic} />
        <meta property="og:description" content={questions[currentQuestionIndex].description} />
        <meta property="og:image" content={questions[currentQuestionIndex].imageHeader} />
        <meta property="og:url" content={`https://survey.axgura.com/survey/${survey_id}`}/>
        <meta property="og:type" content="website" />
        {/* <link rel="icon" href="%PUBLIC_URL%/axgura.jpeg" /> */}
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="apple-touch-icon" href={questions[currentQuestionIndex].imageHeader} />
             </Helmet>
            <form onSubmit={handleSubmit}>
        <section class="section survey" id="contact">
          <div className='intro'>
             
              <div style={{
                  border: "4px dotted brown",
                  borderRadius: "4px",
                  overflow: "hidden",
                  boxShadow: "4px 4px 4px rgba(0, 0, 0, 0.4)",
                  marginBottom: "4%",
                  width: "100%",
                  maxWidth: "100%",
                  height: "auto",
                  boxSizing: "border-box",
                  padding: "2%"
                }}>
                  <img src={questions[currentQuestionIndex].imageHeader} style={{
                    display: "block",
                    maxWidth: "100%",
                    height: "auto",
                    marginLeft: "auto",
                    marginRight: "auto"
                  }} />
                </div>

              <h3 style={{ marginTop: "2px", marginBottom: "10px", padding: "2px", fontSize: "20px", fontWeight: "bolder", textDecoration: "underline grey 2px" }}>
                {questions[currentQuestionIndex].topic}
              </h3>
              <p style={{ textAlign:"left",marginTop:"2px", marginBottom:"10px" ,padding:"2px",fontSize:"13.8px", fontWeight:"bold", display:"flex"}}>{questions[currentQuestionIndex].description}</p>
                    <h2 style={{marginTop:"2px", marginBottom:"8px" ,padding:"2px",fontSize:"18px", fontWeight:"bolder"}}>{"@"+questions[currentQuestionIndex].author}</h2>
                </div>

        <div class="container">
          <div class="survey__content">
        {/* {questions[currentQuestionIndex].question[currentQuestion]} */}
       <div className='survey__data' key={questions[currentQuestionIndex].question[currentQuestion].question_id}>
       <label class="weight-600">
       <p style={{ marginTop:"2px", marginBottom:"10px" ,padding:"2px",fontSize:"20px", fontWeight:"bolder"}}>
       <span style={{color:"red"}} >*</span> 
       {questions[currentQuestionIndex].question[currentQuestion].question}
        </p>
        </label>
    {
      questions[currentQuestionIndex].question[currentQuestion].choice === "text" ? (
        <div className='input'>
          <input 
            type={questions[currentQuestionIndex].question[currentQuestion].choice}
            placeholder={questions[currentQuestionIndex].question[currentQuestion].placeholder}
            value={selectedOption ? selectedOption : ""}
            onChange={(e) => handleOptionSelect(e.target.value)}
            class="survey__email"
          />
        </div>  
      ) : questions[currentQuestionIndex].question[currentQuestion].choice === "radio" ? (
        <div>
          {/* <p>
            <span style={{color:"red",margin:"2px",padding:"0.5px",fontSize:"20px"}}>*</span> 
            {questions[currentQuestionIndex].question[currentQuestion].question}
          </p> */}
          {questions[currentQuestionIndex].question[currentQuestion].placeholder.split(",").map((x,index) => (
                  <div className="form-group col-md-6 col-sm-12" style={{ fontSize: '15px',margin:"5px",paddingTop:"4",marginTop:"3px", padding:"3px", textAlign: 'left', fontWeight:"bold"}}>
                  <div className="custom-control custom-radio mb-4">
                    <input 
                      type="radio" 
                      id="customRadio4" 
                      name="customRadio" 
                      value={x}
                      checked={selectedOption === x}
                      onChange={(e) => setSelectedOption(e.target.value)}
                      className="custom-control-input"
                    />
                    <label className="custom-control-label" htmlFor="customRadio4">{x}</label>
                  </div>
                  </div>

            ))}
         
        </div>
      ) : questions[currentQuestionIndex].question[currentQuestion].choice === "option" ? (
        <div class="form-group row" className='survey__data' key={questions[currentQuestionIndex].question[currentQuestion].question_id}>
          <div class="col-sm-12 col-md-10">
          <select class="custom-select col-12" onChange={(e) => {
            setSelectedOption(e.target.value);
          }}>
            {questions[currentQuestionIndex].question[currentQuestion].placeholder.split(",").map((x,index) => (
              <option key={index} value={x}>{x}</option>
            ))}
          </select>
        </div>
        </div>
      ) : null
    }
     <div className='btn'>
        <button type="submit" disabled={!selectedOption || submitting}
             className="hero__btn">
            {currentQuestion + 1 < questions[currentQuestionIndex].question.length ? 'Next' : submit}
          </button>
        </div>
      </div>

       
        </div>
        </div>
        </section>
            </form>
          </div>
        ):(<div className='centered-div'>
          <p>Loading questions...</p>
        </div>)
      }
  {res_data?
  <div class={popUp?"popup":"popup hide__popup"}>
    <div class="popup__content">
      <div class="popup__close">
        <a onClick={()=>changePopUp(false)}>
        <svg>
          {/* <use xlink:href="./images/sprite.svg#icon-cross"></use> */}
        </svg>
        </a>
      </div>
      <div class="popup__left">
        <div class="popup-img__container">
          <img class="popup__img" src={imageHeader && imageHeader !== "" ?imageHeader:"/img/axgura.jpeg" }alt="popup" />
        </div>
      </div>
      <div class="popup__right">
        <div class="right__content">
          <h1>Thanks for your time!</h1>
          <p style={{display:res_data.rate?"grid":"none"}}>
          We used AI to compare your answers to the actual answers. 
          Here is your response rating
            <br/>
            <br/>
            <h1><span> {res_data.rate == false?"":res_data.rate}% </span></h1>
          </p>
          
          {/* <form action="#">
            <input type="email" placeholder="Enter your email..." class="popup__form" />
            <a href="#">Subscribe</a>
          </form> */}
        </div>
        <div className='tip'>
        <Socials data={res_data.share}/>
        </div>
      </div>
    </div>
  </div>:""}
    </div>
  );
};


export default Survey;
