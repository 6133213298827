 import React, { useEffect, useState } from 'react'
 import {GetAllSurveys} from "../Func/GetSurveysById";
 function Homepage() {
    //const [data,setData]= useState(null);
    async function filterArray(array) {
      return await array.filter(item => item.only_waitlist === false);
    }
    
    const getData =async()=>{
        let data_ = await GetAllSurveys();
        console.log({data_});
        if(data_.bool == true){
          const {data} = data_;
          data_  = await filterArray(data.data);
          console.log({data_});
          const num = await getRandomNumber(data_);
          const url = `/survey/${data_[num]?data_[num].survey_id:"error"}`;
          console.log({url});
          window.location = url;
        }else{
            console.log({data_});
        }
    }
    async function getRandomNumber(array) {
      if(array.length <= 1){
        return (array.length - 1);
      }else{
        return Math.floor(Math.random() * (array.length - 1)) + 1;
      }
      
    }
    useEffect(()=>{
       getData();
    },[]);
   return (
     <div>
      <div class="centered-div">
        Loading random survey
    </div>
</div>
   )
 }
 
 export default Homepage;