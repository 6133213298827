import React, { useEffect, useState } from 'react'
import {GetAllSurveys} from "../Func/GetSurveysById";

function Unknown() {
   const[popUp,setPopUp] =useState(false);
   const changePopUp=(bool)=>{
        setPopUp(bool);
   }
  return (
    <div className='pages'>
        <div className='question'>
     <section class="section survey" id="contact">
        <div className='intro'>
            <div>
                <img src='/img/axgura.jpeg'/>
            </div>
            <h3>Broken URL</h3>
        </div>
      {/* <div class="container">
        <div class="survey__content">
          <div class="survey__data">
          <p>
          <span style={{color:"red",margin:"2px",padding:"0.5px",fontSize:"20px"}}>*</span> 
          Suscribe to marv_electronics to get latest update on your best product
          </p>

        <div className='input'>
        <input type="email" placeholder="Enter your email address" class="survey__email"/>
        </div>        
    <div className='btn'>
    <a onClick={()=>changePopUp(true)} class="survey__link" href="#">Next</a>
    </div>
          </div>
        </div>
      </div> */}
    </section>
     </div>
     <div class={popUp?"popup":"popup hide__popup"}>
    <div class="popup__content">
      <div class="popup__close">
        <a onClick={()=>changePopUp(false)}>
        <svg>
          {/* <use xlink:href="./images/sprite.svg#icon-cross"></use> */}
        </svg>
        </a>
      </div>
      <div class="popup__left">
        <div class="popup-img__container">
          <img class="popup__img" src="/img/axgura.jpeg" alt="popup" />
        </div>
      </div>
      <div class="popup__right">
        <div class="right__content">
          <h1>Thanks for your time!</h1>
          <p>
            We compared your answers with actual answer with the help of AI.
            here's your response rating 
            <br/>
            <br/>
            <h1><span> 67% </span></h1>
          </p>
          {/* <form action="#">
            <input type="email" placeholder="Enter your email..." class="popup__form" />
            <a href="#">Subscribe</a>
          </form> */}
        </div>
      </div>
    </div>
  </div>
 
    </div>
  )
}

export default Unknown;