import logo from './logo.svg';
import './App.css';
import "./CSS/Survey.css";
import { createContext } from 'react';
import {BrowserRouter,Routes,Route, Link} from "react-router-dom";
import Homepage from './Pages/Homepage';
import Survey from './Pages/Survey';
import Unknown from "./Pages/Unknown";
export const create = createContext(null);
function App() {
  return (
   <div>
    <BrowserRouter>
    <create.Provider value={{}}>
    <Routes>
       <Route  path='/' element={<Homepage/>} />
       <Route path='*' element={ <Unknown />} />
       <Route path='/survey/:survey_id/:id' element={<Survey/>} />
       <Route path='/survey/:survey_id' element={<Survey/>} />
    </Routes>
    </create.Provider>
    </BrowserRouter>
   </div>
  );
}

export default App;
